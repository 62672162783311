/* Cant import shared types since webpack does not tree shake all unused types */

const PrivacySetting = {
  Always: 'ALWAYS',
  Never: 'NEVER',
  WithConsent: 'WITH_CONSENT',
};

const DeviceType = {
  Desktop: 'DESKTOP',
  Mobile: 'MOBILE',
  Tablet: 'TABLET',
};

export { PrivacySetting, DeviceType };
