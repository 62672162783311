export default class Container {
  constructor(dist) {
    this.dist = dist;
    this.requiresTrigger = false;
  }

  isActive() {
    return false;
  }
}
