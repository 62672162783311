import setStyle from '../utils/setStyle';
import createCloseButton from '../utils/createCloseButton';

import '../styles/modal.scss';
import '../styles/loading.scss';

const MAIN_CLASSNAME = 'extellio-modal';

export default (dist) => {
  const container = document.createElement('div');
  container.setAttribute('class', `${MAIN_CLASSNAME}__overlay ${dist.opts.id} ${dist.opts.surveyId}`);

  const content = document.createElement('div');
  setStyle(content, {
    width: `${dist.opts.popupWidth}px`,
  });

  content.setAttribute('class', MAIN_CLASSNAME);
  content.setAttribute('role', 'dialog');
  content.setAttribute('aria-modal', 'true');
  content.setAttribute('aria-labelledby', 'extellio-modal-label');

  content.appendChild(createCloseButton(dist, MAIN_CLASSNAME));

  const label = document.createElement('div');
  label.innerText = 'Extellio survey';
  label.setAttribute('id', `${MAIN_CLASSNAME}-label`);

  content.appendChild(label);

  container.appendChild(content);

  return {
    container,
    content,
    // minHeight: dist.opts.popupHeight,
  };
};
