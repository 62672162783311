import button from './button';
import existingButton from './existingButton';
import invisibleLayer from './invisibleLayer';
import none from './none';

const triggers = {
  existingButton,
  invisibleLayer,
  button,
  none,
};

export default dist => new triggers[dist.opts.trigger](dist);
