import portion from './portion';
import active from './active';
import website from './website';
import includedUrls from './includedUrls';
import excludedUrls from './excludedUrls';
import excludedIPs from './excludedIPs';
import frequency from './frequency';
import uniqueTrigger from './uniqueTrigger';
import uniqueContainer from './uniqueContainer';
import delay from './delay';
import sessionDelay from './sessionDelay';
import targetElement from './targetElement';
import deviceTypes from './deviceTypes';
import customExpression from './customExpression';

const validators = [
  active,
  website,
  targetElement,
  deviceTypes,
  includedUrls,
  excludedUrls,
  excludedIPs,
  customExpression,
  frequency,
  portion,
  delay,
  sessionDelay,
  uniqueTrigger,
  uniqueContainer,
];

export default (dist, dists) => {
  for (let i = 0; i < validators.length; i += 1) {
    const res = validators[i](dist, dists);
    if (res !== true) {
      return res;
    }
  }
  return true;
};
