import getRespondentId from '../utils/respondentId';
import log from '../utils/log';
import { PrivacySetting } from '../types';
import analytics from './analytics';
import hsr from './hsr';

export function initMatomo() {
  if (!window.Matomo) {
    log('Matomo not loaded');
    return;
  }

  const { Matomo } = window;
  const { website, data: { trackerBaseUrl, embedded } } = window.extellio;
  let { tracker } = window.extellio;

  if (!tracker || tracker.getSiteId() !== website.matomoId) {
    tracker = Matomo.getTracker(`${trackerBaseUrl}matomo.php`, website.matomoId);
    window.extellio.tracker = tracker;

    if (website.cookiePrefix) {
      tracker.setCookieNamePrefix(`${website.cookiePrefix}_`);
      log('Cookie prefix', website.cookiePrefix);
    }

    if (website.trackVisitors === PrivacySetting.WithConsent) {
      tracker.requireConsent();
      log('Tracking consent required');

      if (tracker.getRememberedConsent()) {
        log('Tracking consent remembered and given');
        window.extellio.analyticalConsentGiven = true;
      }
    }

    if (website.useCookies === PrivacySetting.WithConsent) {
      log('Cookie consent required');
      if (!tracker.requireCookieConsent()) {
        log('Cookie consent remembered and given');
        window.extellio.analyticalCookieConsentGiven = true;
      }
    }

    if (website.useCookies === PrivacySetting.Never || embedded) {
      tracker.disableCookies();
      log('Cookies disabled');
    }

    if (website.enableLinkTracking) {
      tracker.enableLinkTracking();
    }

    if (Matomo.HeatmapSessionRecording) {
      Matomo.HeatmapSessionRecording.disableAutoDetectNewPageView();
      Matomo.HeatmapSessionRecording.disable();
      Matomo.HeatmapSessionRecording.setMatomoTrackers([tracker]);
    }

    if (Matomo.CrashAnalytics) {
      Matomo.CrashAnalytics.disable();
      Matomo.CrashAnalytics.setMatomoTrackers([tracker]);
    }

    if (Matomo.MediaAnalytics) {
      Matomo.MediaAnalytics.disableMediaAnalytics();
      Matomo.MediaAnalytics.setMatomoTrackers([tracker]);
    }

    if (Matomo.FormAnalytics) {
      Matomo.FormAnalytics.disableFormAnalytics();
      Matomo.FormAnalytics.setMatomoTrackers([tracker]);
    }
  }
}

export function matomoOnUrlChange({ initial }) {
  const { canUseAnalyticalCookies, tracker, alwaysSetUserId } = window.extellio;

  if (tracker && (canUseAnalyticalCookies() || alwaysSetUserId)) {
    tracker.setUserId(getRespondentId());
  }

  analytics({ initial });
  hsr();
}
