export default (dist) => {
  const { websiteId, websiteLanguageId } = dist.opts;

  if (!websiteId) {
    return true;
  }

  const { website } = window.extellio;

  if (!website) {
    return 'Website not identified';
  }

  if (websiteId !== website.id) {
    return 'Wrong website';
  }

  if (websiteLanguageId) {
    if (website.websiteLanguage) {
      if (website.websiteLanguage.id !== websiteLanguageId) {
        return `Wrong website language, ${website.websiteLanguage.language}`;
      }
    } else if (website.id !== websiteLanguageId) {
      return `Wrong website language ${website.language}`;
    }
  }

  if (website.reachedResponseLimit) {
    return 'Website reached response limit for this period';
  }

  return true;
};
