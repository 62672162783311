import Trigger from './trigger';
import creatNodeElement from '../utils/createNodeElement';

export default class InvisibleLayer extends Trigger {
  constructor(dist) {
    super(dist);
    this.unique = true;
  }

  show() {
    if (this.el) {
      return;
    }

    this.el = creatNodeElement({
      element: 'div',
      style: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2000,
      },
      className: 'extellio-overlay',
    });

    this.handleClick(() => this.hide());
  }

  hide() {
    if (this.el) {
      this.el.parentNode.removeChild(this.el);
      this.el = null;
    }
  }

  isActive() {
    return !!this.el;
  }
}
