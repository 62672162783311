export default (dist, dists) => {
  if (!dist.container.unique) {
    return true;
  }

  if (dists.some(d => d.opts.id !== dist.opts.id && d.container.unique && d.container.isActive())) {
    return 'There is already another unique container active';
  }

  return true;
};
