import log from './log';
import { getSessionData, setSessionData } from '../storage';

export default function loadUserInfo() {
  const { website, data: { dists, publicPath } } = window.extellio;

  if (website && dists.some(dist => dist.excludedIPs?.length) && !getUserInfo()) {
    return fetch(`${publicPath}userinfo`).then(res => res.json()).then((userInfo) => {
      log('Received userinfo', userInfo);
      setSessionData('userInfo', userInfo);
    });
  }

  return Promise.resolve();
}

export function getUserInfo() {
  return window.extellio.data.userInfo || getSessionData('userInfo', false);
}
