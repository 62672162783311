import { getPersistentData } from '../storage';
import formatTime from '../utils/formatTime';

function diff(timestamp) {
  return (new Date().getTime() - timestamp) / 1000;
}

function checkFrequency(surveyIds, frequency, props) {
  for (let i = 0; i < surveyIds.length; i += 1) {
    const surveyId = surveyIds[i];
    const data = getPersistentData(surveyId);
    const time = diff(Math.max(...props.map(p => data[p]).filter(p => p), 0));

    if (time < frequency) {
      return { surveyId, time };
    }
  }
  return false;
}

export default (dist) => {
  if (dist.opts.frequency) {
    const res = checkFrequency([dist.opts.surveyId, ...dist.opts.groupSurveyIds], dist.opts.frequency, ['responded']);
    if (res) {
      return `Responded to survey (${res.surveyId}, ${formatTime(res.time)}) more recently than frequency (${formatTime(dist.opts.frequency)})`;
    }
  }

  if (dist.opts.declineFrequency) {
    const res = checkFrequency([dist.opts.surveyId, ...dist.opts.groupSurveyIds], dist.opts.declineFrequency, ['shown']);
    if (res) {
      return `Shown survey (${res.surveyId}, ${formatTime(res.time)}) more recently than decline frequency (${formatTime(dist.opts.declineFrequency)})`;
    }
  }

  return true;
};
