export default (dist) => {
  if (!dist.opts.includedUrls || dist.opts.includedUrls.length === 0) {
    return true;
  }

  const url = document.location.href;

  if (dist.opts.includedUrls.some(e => url.match(e))) {
    return true;
  }

  return "Current url doesn't match any included urls.";
};
