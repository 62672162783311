import popupLayer from './popupLayer';
import popupWindow from './popupWindow';
import embed from './embed';
import newWindow from './newWindow';

const containers = {
  popupWindow,
  popupLayer,
  embed,
  newWindow,
};

export default dist => dist.opts.container && containers[dist.opts.container] && new containers[dist.opts.container](dist);
