export default (dist, dists) => {
  if (!dist.trigger.unique) {
    return true;
  }

  if (dists.some(d => d.opts.id !== dist.opts.id && d.trigger.unique && d.trigger.isActive())) {
    return 'There is already another unique trigger active';
  }

  return true;
};
