const h = history; // eslint-disable-line
const { pushState, replaceState } = h;

h.pushState = (...args) => {
  pushState.apply(h, args);
  window.dispatchEvent(new CustomEvent('extellioPushState'));
};

h.replaceState = (...args) => {
  replaceState.apply(h, args);
  window.dispatchEvent(new CustomEvent('extellioReplaceState'));
};
