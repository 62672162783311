import TimeMe from './timeme';

import {
  setPersistentData, getPersistentData, setSessionData, getSessionData, removePersistentData, removeSessionData,
} from '../storage';

const STORAGE_NAME = 'timers';

function log(...args) {
  window.extellio.log('[TimeTracker]', ...args);
}

let started = false;

export function startTimeTracker() {
  stopTimeTracker();
  TimeMe.initialize({
    currentPageName: document.location.href,
  });

  window.addEventListener('beforeunload', stopTimeTracker);
  started = true;
  log('Started');
}

export function stopTimeTracker() {
  if (!started) {
    return;
  }
  window.removeEventListener('beforeunload', stopTimeTracker);
  TimeMe.stopTimer();
  setPersistentData(STORAGE_NAME, getTotalTime());
  setSessionData(STORAGE_NAME, getSessionTime());
  TimeMe.resetAllRecordedPageTimes();
  started = false;
  log('Stopped');
}

function combineTimers(storedTime) {
  return TimeMe.getTimeOnAllPagesInSeconds().reduce((obj, p) => {
    obj[p.pageName] = Math.round((obj[p.pageName] || 0) + p.timeOnPage);
    return obj;
  }, storedTime);
}

function getTotalTime() {
  return combineTimers(getPersistentData(STORAGE_NAME));
}

function getSessionTime() {
  return combineTimers(getSessionData(STORAGE_NAME));
}

function aggregateTimeOnValidPages(pages, dist) {
  return Object.keys(pages).reduce((sum, page) => {
    if (!dist) {
      return sum + pages[page];
    }
    if (!dist.opts.excludedUrls.some(e => page.match(e))) {
      if (!dist.opts.includedUrls.length || dist.opts.includedUrls.some(e => page.match(e))) {
        return sum + pages[page];
      }
    }
    return sum;
  }, 0);
}

export function getTimeOnValidPages(dist) {
  return aggregateTimeOnValidPages(getTotalTime(), dist);
}

export function getTimeOnPage(page = document.location.href) {
  return getTotalTime()[page];
}

export function getSessionTimeOnValidPages(dist) {
  return aggregateTimeOnValidPages(getSessionTime(), dist);
}

export function getSessionTimeOnPage(page = document.location.href) {
  return getSessionTime()[page];
}

// export function whenTimeOnValidPages(dist) {
//   return new Promise((resolve) => {
//     const interval = setInterval(() => {
//       const time = getTimeOnValidPages(dist);
//       if (time > dist.opts.delay) {
//         clearInterval(interval);
//         resolve(time);
//       }
//     }, 1000);
//   });
// }

export function resetTimeTracker() {
  removePersistentData(STORAGE_NAME);
  removeSessionData(STORAGE_NAME);
  TimeMe.stopTimer();
  TimeMe.resetAllRecordedPageTimes();
  if (started) {
    TimeMe.startTimer();
  }
}

export function isStarted() {
  return started;
}
