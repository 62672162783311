import close from '../icons/close.svg';

export default function createCloseButton(dist, prefix) {
  const button = document.createElement('div');
  button.setAttribute('class', `${prefix}__close-button`);
  button.setAttribute('aria-label', 'Close survey');
  button.innerHTML = `
    <div class="${prefix}__close-button-icon">${close}</div>
  `;
  button.onclick = () => {
    dist.close();
  };
  return button;
}
