export default class Trigger {
  constructor(dist) {
    this.dist = dist;
    this.active = false;
  }

  handleClick(cb) {
    if (this.el) {
      this.el.addEventListener('click', () => {
        if (cb) cb();
        this.dist.shouldOpen();
      }, false);
    }
  }
}
