import log from './log';

export default (dist, resolve) => {
  window.addEventListener('message', ({ data }) => {
    if (!data || data.distribution !== dist.opts.id) {
      return;
    }

    log(dist.opts.id, 'postmessage', data);

    if (data.responseSaved) {
      dist.responseSaved();
    }

    if (data.answerLater) {
      dist.answerLater();
    }

    if (data.close) {
      dist.close();
    }

    if (data.loaded && resolve) {
      resolve();
    }
  });
};
