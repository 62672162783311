import { PrivacySetting } from './types';
import getRespondentId from './utils/respondentId';
import { resetTimeTracker, startTimeTracker, stopTimeTracker } from './utils/timeTracker';
import iframeResizer from './utils/iframeResizer';
import log from './utils/log';
import addStyle from './utils/addStyle';
import domReady from './utils/domReady';
import loadUserInfo from './utils/userInfo';
import validate from './validators';
import Distribution from './distribution';
import { initMatomo, matomoOnUrlChange } from './matomo/matomo';
import supportedBrowsers from '../supportedBrowsers';
import './utils/pushStateEvent';
import ping from './utils/ping';
import { loadCustomExpression } from './utils/customExpression';
import { removePersistentData } from './storage';
import { getBooleanQueryParam, getQueryParam } from './utils/queryParams';
import normalizeUrl from './utils/normalizeUrl';
import initActions from './actions';

let mainInterval;
let bootstrap;

function isSupportedBrowser(userAgent) {
  return userAgent && supportedBrowsers.test(userAgent);
}

function chain(callbacks) {
  return callbacks.reduce((prev, next) => prev.then(() => {
    const res = next();

    if (res instanceof Promise) {
      return res.catch(e => {
        log(e);
      });
    }

    return res;
  }), Promise.resolve());
}

function loadDebug() {
  if (!window.extellio.embedded && window.extellio.debug) {
    log('Activating debug mode');

    return import(/* webpackChunkName: "debug" */ './debug/debug')
      .then((module) => {
        window.extellio.debug = module.default;
      });
  }
  return null;
}

function loadData() {
  const { publicPath, key, autoStart } = window.extellio.data;
  if (getBooleanQueryParam('extellio_debug') || getQueryParam('extellio_hsr_screenshot')) {
    log('Loading uncached data');
    return fetch(`${publicPath}data/${key}?autoStart=${autoStart}&t=${Date.now()}`).then(res => res.json()).then(d => {
      window.extellio.data = { ...window.extellio.data, ...d };
    });
  }
  return null;
}

function identifyWebsite() {
  const currentUrl = normalizeUrl(document.location.href);

  const websiteUrls = window.extellio.data.websites.reduce((obj, w) => ({
    ...obj,
    [normalizeUrl(w.url)]: w,
    ...w.additionalLanguages.reduce((acc, l) => ({ ...acc, [normalizeUrl(l.url)]: { ...w, websiteLanguage: l } }), {}),
    ...w.additionalUrls.reduce((acc, url) => ({ ...acc, [normalizeUrl(url)]: w }), {}),
  }), {});

  // prioritize longer urls
  const sortedWebsiteUrls = Object.keys(websiteUrls).sort((a, b) => b.length - a.length);

  const matchingUrl = sortedWebsiteUrls.find(url => currentUrl.indexOf(url) === 0);

  let website;

  if (matchingUrl) {
    website = websiteUrls[matchingUrl];

    const excludedUrl = website.excludedUrls.find(e => currentUrl.match(normalizeUrl(e)));
    if (excludedUrl) {
      log('Current url is excluded for website', excludedUrl);
      website = null;
    }
  } else {
    log('Could not identify website based on current url', currentUrl, sortedWebsiteUrls);
  }

  if (website) {
    if (!window.extellio.website || website.id !== window.extellio.website.id || (website.websiteLanguage && website.websiteLanguage.id !== window.extellio.website.websiteLanguage?.id)) {
      log('Identified website', matchingUrl, website);
      window.extellio.website = website;

      if (website.customCSS) {
        addStyle(website.customCSS);
      }

      initMatomo();
      initActions();
    }
  } else {
    window.extellio.website = null;
  }
}

const middleware = [
  iframeResizer,
  loadDebug,
  loadData,
  loadCustomExpression,
  domReady,
  () => {
    log('Got config', window.extellio.data);
    window.extellio.dists = window.extellio.data.dists.map(d => new Distribution(d));
    identifyWebsite();
    window.extellio.debug?.();
  },
];

function shouldTrackTime() {
  return window.extellio.dists.some(d => d.shouldTrackTime());
}

function canUseAnalyticalCookies() {
  if (window.extellio?.website?.useCookies === PrivacySetting.Always) {
    return true;
  }
  if (window.extellio?.website?.useCookies === PrivacySetting.WithConsent && window.extellio?.analyticalCookieConsentGiven) {
    return true;
  }
  return false;
}

function shouldTrigger() {
  if (window.extellio?.website) {
    window.extellio?.dists.forEach(d => d.shouldTrigger(validate(d, window.extellio.dists)));
  }
}

function init(data) {
  if (window.extellio.initialized) {
    log('Already initialized');
    return;
  }

  window.extellio.initialized = true;

  if (data.debug || getBooleanQueryParam('extellio_debug')) {
    window.extellio.debug = true;
  }

  log('Init');

  if (!isSupportedBrowser(navigator.userAgent)) {
    log('Browser not supported.', navigator.userAgent);
    return;
  }

  window.extellio.data = data;

  if (data.key === 'survey') {
    window.extellio.preview = getBooleanQueryParam('isPreview');
    window.extellio.embedded = getBooleanQueryParam('embedded');
    window.extellio.alwaysSetUserId = true;
  }

  if (!bootstrap) {
    bootstrap = chain(middleware);
    window.addEventListener('popstate', onUrlChange);
    window.addEventListener('extellioPushState', onUrlChange);
    window.addEventListener('extellioReplaceState', onUrlChange);
  }

  if (data.autoStart) {
    start();
  }
}

function onUrlChange({ initial = false }) {
  if (isStarted()) {
    log('Url changed', window.location.href);
    identifyWebsite();

    if (window.extellio.website) {
      if (shouldTrackTime()) {
        startTimeTracker();
      }

      shouldTrigger();
      matomoOnUrlChange({ initial });
      ping();
    } else {
      stopTimeTracker();
    }
  }
}

function start() {
  if (!isStarted()) {
    if (!window.extellio.initialized) {
      log('Not initialized');
      return;
    }

    log('Starting');

    bootstrap.then(loadUserInfo).then(() => {
      mainInterval = setInterval(() => {
        shouldTrigger();
      }, 1000);

      onUrlChange({ initial: true });
    });
  } else {
    log('Already started');
  }
}

function stop() {
  if (isStarted()) {
    log('Stopping');
    clearInterval(mainInterval);
    mainInterval = false;

    window.extellio.dists?.forEach(d => d.hide());
    stopTimeTracker();
  } else {
    log('Not started');
  }
}

function isStarted() {
  return !!mainInterval;
}

function removeSurveyData() {
  window.extellio.dists.forEach(d => removePersistentData(d.opts.surveyId));
  resetTimeTracker();
}

export default {
  init,
  start,
  stop,
  isStarted,
  getRespondentId,
  log,
  canUseAnalyticalCookies,
  removeSurveyData,
};
