import generateSurveyUrl from '../utils/generateSurveyUrl';
import getWindowPosition from '../utils/getWindowPosition';

import NewWindow from './newWindow';

export default class PopupWindow extends NewWindow {
  constructor(dist) {
    super(dist);
    this.unique = true;
  }

  openWindow() {
    const { opts } = this.dist;
    const { top, left } = getWindowPosition(opts);

    return window.open(generateSurveyUrl(this.dist), 'extellio', `resizable=yes,scrollbars=yes,width=${opts.popupWidth},height=${opts.popupHeight},top=${top},left=${left}`);
  }
}
