function format(value, mod) {
  return Math.floor(mod ? value % mod : value);
}

export default function formatTime(seconds) {
  if (!seconds) {
    return '-';
  }

  if (seconds < 60) {
    return `${format(seconds)}s`;
  }

  const parts = [format(seconds, 60), format(seconds / 60, 60)];

  if (seconds >= 60 * 60) {
    parts.push(format(seconds / 60 / 60, 24));
  }

  if (seconds >= 60 * 60 * 24) {
    parts.push(format(seconds / 60 / 60 / 24));
  }

  const s = parts.reverse().join(':').replace(/\b(\d)\b/g, '0$1');

  if (parts.length === 4) {
    return s.replace(':', 'd');
  }

  return s;
}
