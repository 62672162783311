let filtrex;

export function compileCustomExpression(dist) {
  if (dist.opts.customExpression) {
    return filtrex.compileExpression(dist.opts.customExpression, {
      customProp: filtrex.useDotAccessOperatorAndOptionalChaining,
      extraFunctions: {
        inArray(array, value, property) {
          return array ? array.some(a => (property ? filtrex.useDotAccessOperatorAndOptionalChaining(property, null, a, null) === value : a === value)) : false;
        },
      },
    });
  }
  return null;
}

export function loadCustomExpression() {
  const { dists } = window.extellio.data;

  if (dists && dists.some(dist => dist.customExpression)) {
    // __webpack_public_path__ = publicPath; // eslint-disable-line

    return import(/* webpackChunkName: "filtrex" */ './filtrex')
      .then((module) => {
        filtrex = module.default;
      });
  }

  return null;
}
