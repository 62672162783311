import { DeviceType } from '../types';

export default (dist) => {
  if (!dist.opts.deviceTypes) {
    return 'No devices included';
  }

  // Force mobile dist on Chrome OS (problem with separate windows getting lost)
  if (window.innerWidth < 767 || window.navigator.userAgent.indexOf('CrOS') !== -1) {
    return dist.opts.deviceTypes.includes(DeviceType.Mobile) || 'Mobile devices excluded';
  }

  if (window.innerWidth < 991) {
    return dist.opts.deviceTypes.includes(DeviceType.Tablet) || 'Tablet devices excluded';
  }

  return dist.opts.deviceTypes.includes(DeviceType.Desktop) || 'Desktop devices excluded';
};
