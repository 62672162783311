import xtend from 'xtend';

const PERSISTENT_NAME = 'espace';
const SESSION_NAME = 'espace_session';

const persistentStorage = window.localStorage;
const sessionStorage = window.sessionStorage;

function log(...args) {
  window.extellio.log('storage', ...args);
}

function isObject(data) {
  return data && typeof data === 'object';
}

function getCookiePrefix() {
  return window.extellio.website.cookiePrefix;
}

function getData(storage, name, key, def) {
  let data;

  if (!window.extellio.website) {
    return def;
  }

  if (key && getCookiePrefix()) {
    name = `${getCookiePrefix()}_${key}`;
    key = false;
  }

  try {
    const raw = storage.getItem(name);
    if (raw === null) {
      return def;
    }
    data = JSON.parse(raw);
  } catch (e) {
    log('Error getting data', e);
    return def;
  }

  if (key && isObject(data)) {
    data = data[key];
  }

  return data !== undefined ? data : def;
}

function setData(storage, name, key, newData, merge = true) {
  if (!window.extellio.website) {
    return false;
  }

  if (getCookiePrefix()) {
    name = `${getCookiePrefix()}_${key}`;
    key = false;
  }

  try {
    let data = getData(storage, name, false, key ? {} : '');

    if (key) {
      data[key] = isObject(data) && isObject(newData) && merge && data[key] ? xtend(data[key], newData) : newData;
    } else {
      data = isObject(data) && isObject(newData) && merge ? xtend(data, newData) : newData;
    }

    if (data === undefined) {
      storage.removeItem(name);
      return true;
    }

    const json = JSON.stringify(data);

    storage.setItem(name, json);
    return storage.getItem(name) === json;
  } catch (e) {
    log('Error setting data', e);
    return false;
  }
}

export function getPersistentData(key, def = {}) {
  return getData(persistentStorage, PERSISTENT_NAME, key, def);
}

export function setPersistentData(key, newData, merge = true) {
  return setData(persistentStorage, PERSISTENT_NAME, key, newData, merge);
}

export function removePersistentData(key) {
  setData(persistentStorage, PERSISTENT_NAME, key, undefined, false);
}

export function getSessionData(key, def = {}) {
  return getData(sessionStorage, SESSION_NAME, key, def);
}

export function setSessionData(key, newData, merge = true) {
  return setData(sessionStorage, SESSION_NAME, key, newData, merge);
}

export function removeSessionData(key) {
  setData(sessionStorage, SESSION_NAME, key, undefined, false);
}

export function hasStorage() {
  return persistentStorage && sessionStorage;
}
