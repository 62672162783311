import normalizeUrl from '../utils/normalizeUrl';
import { getIntQueryParam } from '../utils/queryParams';
import logger from '../utils/log';

import '../styles/message.scss';

const log = (...args) => logger('[HSR]', ...args);

function createScreenshotModal(screenshotHsrId, activeHeatmaps, websiteHeatmaps) {
  const modal = document.createElement('div');
  modal.classList.add('extellio-message');

  let message;
  let showButton = false;

  if (activeHeatmaps.find(h => h.id === screenshotHsrId)) {
    log('Showing screenshot modal for', screenshotHsrId);
    message = 'Wait until the page has finished loading, then click the button to capture a screenshot of the page.';
    showButton = true;
  } else if (websiteHeatmaps.find(h => h.id === screenshotHsrId)) {
    log('Heatmap is not active on this url.', screenshotHsrId);
    message = 'The heatmap is not active on this page, check the rules in the Heatmap settings to make sure your are on the right URL. You can still capture a screenshot if you want.';
    showButton = true;
  } else {
    log('Heatmap not found.', screenshotHsrId);
    message = 'The heatmap was not found, make sure that it\'s active.';
  }

  modal.innerHTML = `
              <div style="font-weight: bold; margin-bottom: 10px;">Heatmap screenshot</div>
              <div style="margin-bottom: 10px">${message}</div>              
          `;

  if (showButton) {
    const button = document.createElement('button');
    button.classList.add('extellio-button', 'extellio-button--standard');
    button.innerHTML = '<div class="extellio-button__label">Capture screenshot</div>';

    button.addEventListener('click', () => {
      modal.classList.remove('open');
      window.Matomo.HeatmapSessionRecording.enable({
        heatmaps: [{
          id: screenshotHsrId,
          sample_rate: 100,
          getdom: true,
          capture_manually: false,
        }],
      });
    });
    modal.appendChild(button);
  }

  document.body.appendChild(modal);
  setTimeout(() => modal.classList.add('open'), 100);
}

function getMatchingHsr(hsr) {
  return hsr.filter(h => h.rules.every(r => {
    let matchAgainst;
    let value = r.value;

    if (r.attribute === 'url') {
      if (r.type === 'equals_simple') {
        matchAgainst = normalizeUrl(`${window.location.origin}${window.location.pathname}`);
      } else {
        let search;
        if (window.location.search) {
          const params = new URLSearchParams(window.location.search);
          params.delete('pk_hsr_forcesample');
          params.delete('pk_hsr_capturescreen');
          search = params.toString();
        }

        matchAgainst = `${window.location.origin}${window.location.pathname}${search ? `?${search}` : ''}`;
      }
    } else if (r.attribute === 'path') {
      matchAgainst = window.location.pathname;
    } else if (r.attribute === 'urlparam') {
      matchAgainst = new URLSearchParams(window.location.search).get(r.value);
      value = r.value2;
    }

    let res;

    switch (r.type) {
      case 'exists':
        res = !!matchAgainst;
        break;
      case 'equals_exactly':
        res = matchAgainst === value;
        break;
      case 'equals_simple':
        res = matchAgainst === normalizeUrl(value);
        break;
      case 'contains':
        res = matchAgainst?.includes(value);
        break;
      case 'starts_with':
        res = matchAgainst?.startsWith(value);
        break;
      case 'regexp':
        res = new RegExp(value).test(matchAgainst);
        break;
      default:
        res = false;
    }

    return r.inverted ? !res : !!res;
  }));
}

export default function onUrlChange() {
  const { website, tracker } = window.extellio;
  const { Matomo } = window;

  if (tracker?.HeatmapSessionRecording) {
    Matomo.HeatmapSessionRecording.setNewPageView(false);

    let heatmaps = getMatchingHsr(website.heatmaps);

    const screenshotHsrId = getIntQueryParam('extellio_hsr_screenshot');

    if (screenshotHsrId) {
      createScreenshotModal(screenshotHsrId, heatmaps, website.heatmaps);
    } else {
      if (heatmaps.length > 0 && website.reachedDailyHeatmapSampleLimit) {
        log('Not tracking heatmaps because of license limit');
        heatmaps = [];
      }

      let sessions = getMatchingHsr(website.sessionRecordings);

      if (sessions.length > 0 && website.reachedDailySessionRecordingLimit) {
        log('Not tracking session recordings because of license limit');
        sessions = [];
      }

      if (heatmaps.length > 0 || sessions.length > 0) {
        log('Adding hsr config', { heatmaps, sessions });
        Matomo.HeatmapSessionRecording.enable({ heatmaps, sessions });
      }
    }
  }
}
