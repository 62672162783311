import Trigger from './trigger';
import creatNodeElement from '../utils/createNodeElement';
import '../styles/button.scss';

/**
 * source of the svgs is
 * https://github.com/ivanvotti/font-awesome-svg/tree/master/SVG
 */
import clipboard from '../icons/clipboard.svg';
import comment from '../icons/comment.svg';
import comments from '../icons/comments.svg';
import list from '../icons/list-alt.svg';

const icons = {
  clipboard,
  comment,
  comments,
  'list-alt': list,
};

export default class Button extends Trigger {
  show() {
    if (this.el) {
      if (this.el.style.display === 'none') {
        this.el.style.display = '';
      }
      return;
    }

    let html = '';

    const {
      id, surveyId, buttonIcon, buttonLabel, triggerStyle, triggerPosition, theme, customTheme,
    } = this.dist.opts;

    if (triggerStyle === 'floatingButton' && icons[buttonIcon]) {
      html = `<div class="extellio-button__icon">${icons[buttonIcon]}</div>`;
    }

    if (triggerStyle === 'tab' && buttonLabel) {
      html = `<div class="extellio-button__label">${buttonLabel}</div>`;
    }

    let buttonBg = '#1a2f55';
    let buttonTextColor = '#fff';

    if (theme === 'custom' && customTheme) {
      if (customTheme.buttonColor) {
        buttonBg = customTheme.buttonColor;
      }
      if (customTheme.buttonTextColor) {
        buttonTextColor = customTheme.buttonTextColor;
      }
    }

    this.el = creatNodeElement({
      html,
      element: 'button',
      style: { backgroundColor: buttonBg, color: buttonTextColor },
      className: `extellio-button extellio-button--${triggerStyle} extellio-button--${triggerPosition} ${surveyId} ${id}`,
    });

    this.handleClick();
  }

  hide() {
    if (this.el) {
      this.el.style.display = 'none';
    }
  }

  isActive() {
    return this.el && this.el.style.display !== 'none';
  }
}
