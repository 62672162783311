import { getTimeOnValidPages } from '../utils/timeTracker';
import formatTime from '../utils/formatTime';
import { getPersistentData } from '../storage';

export default (dist) => {
  if (!dist.opts.delay) {
    return true;
  }

  const { shown = 0, closed = 0 } = getPersistentData(dist.opts.surveyId);

  const latestInteraction = Math.max(shown, closed);

  const timePassed = (new Date().getTime() - latestInteraction) / 1000;

  if (dist.opts.delay > timePassed) {
    return `Delay (${formatTime(dist.opts.delay)}) is longer than time since interacted with survey (${formatTime(timePassed)}).`;
  }

  const time = getTimeOnValidPages(dist);

  if (dist.opts.delay > time) {
    return `Delay (${formatTime(dist.opts.delay)}) is longer than time spent on valid pages (${formatTime(time)}).`;
  }

  return true;
};
