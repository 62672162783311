import xtend from 'xtend';
import toQueryString from './toQueryString';
import getRespondentId from './respondentId';
import getWebpageInfo from './getWebpageInfo';

export default ({ opts }, extraArgs = {}) => {
  const base = opts.surveyUrl;
  let sep = '';

  let args = xtend(
    extraArgs,
    getWebpageInfo(),
    {
      respondentId: getRespondentId(),
      answerLater: opts.answerLaterTrigger,
    },
  );

  if (window.extellio.debug) {
    args.isPreview = true;
  }

  args = toQueryString(args);

  if (args) {
    sep = base.indexOf('?') === -1 ? '?' : '&';
  }

  return `${base}${sep}${args}`;
};
