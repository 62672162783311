import None from '../triggers/none';

export default (dist) => {
  if (dist.opts.status !== 'ACTIVE') {
    return `Distribution is not active: ${dist.opts.status}`;
  }

  if (!dist.container) {
    return 'No container selected';
  }

  if (dist.container.isActive()) {
    return 'Container is active';
  }

  if (dist.container.requiresTrigger && dist.trigger instanceof None) {
    return 'This container must have a trigger';
  }

  return true;
};
