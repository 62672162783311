import Trigger from './trigger';

export default class None extends Trigger {
  show() {
    this.dist.shouldOpen();
  }

  hide() {
    // do nothing
  }

  isActive() {
    return this.dist.container.isActive();
  }
}
