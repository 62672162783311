import loadSurvey from '../utils/loadSurvey';
import Container from './container';

export default class Embed extends Container {
  show() {
    if (!this.isActive() || this.promise) {
      this.targetElement = document.querySelector(this.dist.opts.elementSelector);

      if (this.targetElement) {
        this.el = document.createElement('div');
        this.el.classList.add('extellio-embed');
        this.el.classList.add(this.dist.opts.id);
        this.el.classList.add(this.dist.opts.surveyId);
        this.targetElement.appendChild(this.el);
        this.promise = loadSurvey(this.el, this.dist, false, false);
      } else {
        return Promise.reject(new Error(`Could not find element with selector ${this.dist.opts.elementSelector}`));
      }
    }

    return this.promise;
  }

  hide() {
    // Do nothing
  }

  isActive() {
    return this.el && this.targetElement === document.querySelector(this.dist.opts.elementSelector);
  }
}
