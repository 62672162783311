import { getPersistentData, setPersistentData } from '../storage';

const RESPONDENT_ID = 'respondentId';

let respondentId;

function generate() {
  const t = new Date().getTime().toString();
  const n = (Math.random() * 1e16).toString().substring(0, 5);
  return `${t}${n}`;
}

export default function getRespondentId(persist = window.extellio?.canUseAnalyticalCookies()) {
  const queryParam = document.location.search.match(/respondentId=(\d+)/);
  const persistedRespondentId = persist && getPersistentData(RESPONDENT_ID, false);

  if (queryParam) {
    respondentId = queryParam[1];
  } else if (persistedRespondentId) {
    respondentId = persistedRespondentId.toString();
    // Fix for malformed id
    if (respondentId.includes('.')) {
      respondentId = respondentId.replace('.', '');
    }
  }

  if (!respondentId) {
    respondentId = generate();
  }

  if (persist && respondentId !== persistedRespondentId) {
    setPersistentData(RESPONDENT_ID, respondentId, false);
  }

  return respondentId;
}
