export default (dist) => {
  if (dist.compiledCustomExpression) {
    try {
      if (!dist.compiledCustomExpression({
        window,
        distribution: dist.opts,
        data: window.extellio.data,
      })) {
        return `Custom expression did not pass. ${dist.opts.customExpression}`;
      }
    } catch (e) {
      return `Custom expression threw an error: ${e.message}`;
    }
  }

  return true;
};
