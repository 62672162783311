import createIframe from './createIframe';
import createLoading from './createLoading';

export default (container, dist, showLoading, minHeight, resolveOnLoaded) => {
  let loading;

  if (showLoading) {
    loading = createLoading();
    container.appendChild(loading);
  }

  return createIframe(container, dist, minHeight, resolveOnLoaded)
    .finally(() => {
      if (loading) {
        container.removeChild(loading);
      }
    });
};
