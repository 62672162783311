import createCloseButton from '../utils/createCloseButton';
import '../styles/speech-bubble.scss';

const MAIN_CLASSNAME = 'extellio-speech-bubble';

export default (dist) => {
  const container = document.createElement('div');
  container.setAttribute('class', `${MAIN_CLASSNAME} ${MAIN_CLASSNAME}--${dist.opts.triggerPosition} ${MAIN_CLASSNAME}--${dist.opts.triggerStyle} ${dist.opts.id} ${dist.opts.surveyId}`);

  const wrapper = document.createElement('div');
  wrapper.setAttribute('class', `${MAIN_CLASSNAME}__wrapper`);
  container.appendChild(wrapper);

  const content = document.createElement('div');
  content.setAttribute('class', `${MAIN_CLASSNAME}__content`);
  wrapper.appendChild(createCloseButton(dist, MAIN_CLASSNAME));
  wrapper.appendChild(content);

  container.setAttribute('role', 'dialog');

  return { container, content, minHeight: 150 };
};
