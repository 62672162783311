import Trigger from './trigger';

export default class ExistingButton extends Trigger {
  show() {
    this.el = document.querySelector(this.dist.opts.elementSelector);

    this.handleClick();
  }

  hide() {
    this.el = null;
  }

  isActive() {
    return !!this.el;
  }
}
