import '../styles/loading.scss';

export default function createLoading(dimensions) {
  const getStyle = () => {
    if (!dimensions) {
      return '';
    }
    return `height: ${dimensions.height}px`;
  };
  const loading = document.createElement('div');
  loading.setAttribute('class', 'extellio-loading');
  loading.setAttribute('style', getStyle());
  loading.innerHTML = `
    <div class="extellio-loading__container">
      <div class="extellio-loading__bar"></div>
    </div>
  `;
  return loading;
}
