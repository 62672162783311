import { getSessionData, setSessionData } from '../storage';

export default (dist) => {
  if (dist.opts.portion && dist.opts.portion < 100) {
    let { excluded } = getSessionData(dist.opts.id);
    if (excluded === undefined) {
      const r = Math.random() * 100;
      excluded = r > dist.opts.portion;
      setSessionData(dist.opts.id, { excluded });
      dist.log('Portion', dist.opts.portion, r, excluded);
    }

    if (excluded) {
      return `Excluded, ${dist.opts.portion}% of visits are included`;
    }
  }
  return true;
};
