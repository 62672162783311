import generateSurveyUrl from '../utils/generateSurveyUrl';
import postMessage from '../utils/postMessage';
import Container from './container';

export default class NewWindow extends Container {
  constructor(dist) {
    super(dist);
    this.requiresTrigger = true;
  }

  show() {
    this.win = this.openWindow();

    this.win.focus();

    postMessage(this.dist);

    const timer = setInterval(() => {
      if (this.win && this.win.closed) {
        clearInterval(timer);
        this.dist.close();
      }
    }, 500);

    return Promise.resolve(true);
  }

  openWindow() {
    return window.open(generateSurveyUrl(this.dist), '_blank');
  }

  hide() {
    if (this.win) {
      this.win.close();
      this.win = null;
    }
  }

  isActive() {
    return !!this.win;
  }
}
