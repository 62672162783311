import { getSessionTimeOnValidPages } from '../utils/timeTracker';
import formatTime from '../utils/formatTime';

export default (dist) => {
  if (!dist.opts.sessionDelay) {
    return true;
  }

  const time = getSessionTimeOnValidPages(dist);

  if (dist.opts.sessionDelay > time) {
    return `Session delay (${formatTime(dist.opts.sessionDelay)}) is longer than time spent on valid pages this session (${formatTime(time)}).`;
  }

  return true;
};
