export default ({
  popupWidth,
  popupHeight,
}) => {
  const screenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const screenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;

  const left = Math.round(((width / 2) - (popupWidth / 2)) + screenLeft);
  const top = popupHeight ? Math.round(((height / 2) - (popupHeight / 2)) + screenTop) : 200;

  return {
    left,
    top,
  };
};
