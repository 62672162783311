export default (dist) => {
  const { elementSelector } = dist.opts;

  if (!elementSelector) {
    return true;
  }

  if (!document.querySelector(elementSelector)) {
    return `Element does not exist '${elementSelector}'`;
  }

  return true;
};
