import setStyle from './setStyle';

export default function createNodeElement({
  id,
  className,
  style,
  element = 'div',
  html = '',
}) {
  const node = document.createElement(element);
  if (style) {
    setStyle(node, style);
  }
  if (className) {
    node.setAttribute('class', className);
  }
  if (id) {
    node.setAttribute('id', id);
  }
  node.innerHTML = html;

  document.body.appendChild(node);

  return node;
}
