import { PrivacySetting } from '../types';

function log(...args) {
  window.extellio.log('ping', ...args);
}

export default function ping() {
  const { website, data: { publicPath } } = window.extellio || {};

  if (website && website.trackVisitors === PrivacySetting.Never) {
    log(website.id);
    fetch(`${publicPath}ping/${website.id}`).catch((err) => log(err));
  }
}
