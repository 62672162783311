export function getQueryParam(name) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name);
}

export function getBooleanQueryParam(name) {
  const value = getQueryParam(name);
  return value === 'true' || value === '1';
}

export function getIntQueryParam(name) {
  const value = getQueryParam(name);
  return parseInt(value, 10);
}
