import { getUserInfo } from '../utils/userInfo';

export default (dist) => {
  if (!dist.opts.excludedIPs || dist.opts.excludedIPs.length === 0) {
    return true;
  }

  const userInfo = getUserInfo();

  if (!userInfo || !userInfo.ip) {
    return true;
  }

  if (dist.opts.excludedIPs.some(e => userInfo.ip.match(e))) {
    return `Client IP (${userInfo.ip}) matches an excluded IP.`;
  }

  return true;
};
